import React, { useState } from 'react'
import Button from '../button'
import { Formik } from 'formik'
import { loginSchema, registerSchema } from '../../schema'
import logo from '../../assets/icons/logo.png'
import loginBg from '../../assets/icons/login_bg.png'
import app_api from '../../config'
import { useNavigate } from 'react-router-dom'

const SignIn = ({ loading, error, login, batches }) => {
  return (
    <>
      <div className="relative flex min-h-screen text-left">
        <div className="flex flex-col justify-center flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute top-8 left-8">
            <img className="w-auto h-8" src={logo} alt="Your Company" />
          </div>
          <div className="w-full max-w-sm mx-auto">
            <div>
              <h2 className="text-3xl font-medium tracking-tight text-gray-900">
                Sign Up
              </h2>
              <p className="my-2 text-sm font-normal text-gray-500">
                Welcome! Please enter your details
              </p>
            </div>

            <div className="mt-8">
              {error !== null && (
                <p className="text-sm text-red-700">{error}</p>
              )}
              <div className="mt-6">
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: '',
                    password: '',
                    fullName: '',
                    githubUsername: '',
                    batchId: batches[0]?.id ? batches[0]?.id : 0,
                  }}
                  validationSchema={registerSchema}
                  onSubmit={login}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form
                      className="space-y-5"
                      onSubmit={handleSubmit}
                      noValidate={true}
                    >
                      {error && <p className="text-sm text-red-700">{error}</p>}
                      <div>
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Full Name
                        </label>
                        <div className="mt-1">
                          <input
                            id="fullName"
                            placeholder="Full name"
                            name="fullName"
                            type="text"
                            autoComplete
                            required={true}
                            value={values.fullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                          {touched.fullName && (
                            <p className="text-xs text-red-700 ">
                              {errors.fullName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            placeholder="Email"
                            name="email"
                            type="email"
                            autoComplete="new-email"
                            required={true}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                          {touched.email && (
                            <p className="text-xs text-red-700 ">
                              {errors.email}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              placeholder="New password"
                              name="password"
                              type="password"
                              autoComplete="new-password"
                              required={true}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                            {touched.password && (
                              <p className="text-xs text-red-700 ">
                                {errors.password}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="githubUsername"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Github username
                        </label>
                        <div className="mt-1">
                          <input
                            id="githubUsername"
                            placeholder="Github username"
                            name="githubUsername"
                            type="text"
                            // autoComplete
                            required={true}
                            // value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                          {touched.githubUsername && (
                            <p className="text-xs text-red-700 ">
                              {errors.githubUsername}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="batchId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Batch
                        </label>
                        <div className="mt-1">
                          <select
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="batchId"
                            id="batchId"
                            value={values.batchId}
                            onChange={handleChange}
                          >
                            <option>select a batch</option>
                            {batches.map((b, i) => (
                              <option key={i} value={b.id}>
                                {b?.name || b?.batchId}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="">
                        <Button
                          type="submit"
                          className=" bg-primary"
                          loading={loading}
                        >
                          Sign In
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
                <div>
                  <p className="text-sm inline-flex">
                    Already a member? &nbsp;
                    <a
                      href="/login"
                      className="text-sm underline font-semibold"
                    >
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-1/2 text-center md:flex-1 lg:block bg-grey-bg">
          <div className="absolute top-0 bottom-0 right-0 flex items-center">
            <img className="w-full h-full py-10" src={loginBg} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
