import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckCircleIcon} from '@heroicons/react/24/outline'

import Button from '../components/button'

const Success = () => {
  const navigate = useNavigate()
  return (
    <div className="h-screen bg-gray-100 flex justify-center items-center">
      <div className="p-6">
        <CheckCircleIcon className='mx-auto w-24 h-24 text-green-500' />
        <div className="text-center">
          <h3 className="text-base font-semibold text-center text-gray-900 md:text-2xl">
            Congratulations!
          </h3>
          <p className="my-2 text-gray-600">
            You've successfully complete this course!
          </p>
          <p> Have a great day!</p>
          <div className="py-10 text-center">
            <Button
              onClick={() => navigate('/landing')}
              className="font-semibold text-white bg-indigo-600 w-[200px] hover:bg-indigo-500"
            >
              GO BACK
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success
