import * as Yup from 'yup'

export const registerSchema = Yup.object({
  fullName: Yup.string().required('Name is required'),
  email: Yup.string().email('Enter valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  githubUsername: Yup.string().required('Github username is required'),
})

export const loginSchema = Yup.object({
  email: Yup.string().email('Enter valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const resetPasswordSchema = Yup.object({
  passowrd: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Please enter the password again to confirm')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
})
