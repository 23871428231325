import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import app_api from '../../config'
import SignIn from '../../components/auth/signin'

const SignUp = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [cogoportKey, setCogoportKey] = useState(
    localStorage.getItem('cogoportKey')
  )

  const [batches, setBatches] = useState([])

  useEffect(() => {
    app_api
      .get('batch')
      .then((res) => setBatches(res.data))
      .catch((err) => {})
  }, [])

  useEffect(() => {
    localStorage.getItem('cogoportKey') && navigate('/landing')
  }, [cogoportKey])

  const performLogin = (values) => {
    setLoading(true)
    app_api
      .post('auth/register', {
        ...values,
        batchId: parseInt(values.batchId),
        streamId: 1,
      })
      .then((res) => res.data.data)
      .then((res) => {
        navigate('/login')
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Error registering...')
      })
  }

  return (
    <div>
      <SignIn
        loading={loading}
        error={error}
        login={performLogin}
        batches={batches}
      />
    </div>
  )
}

export default SignUp
