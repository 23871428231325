import Button from '../../components/button'
import { Formik } from 'formik'
import { resetPasswordSchema } from '../../schema'
import logo from "../../assets/icons/logo.png"
import loginBg from '../../assets/icons/login_bg.png'
import { useEffect, useState } from 'react'

const ResetPassword = ({ loading, error, login }) => {

  const [user_m, stepUser_m] = useState([])

  useEffect(() => {
    try {
      stepUser_m(JSON.parse(localStorage.getItem('cogoportKey')).data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <>
      <div className="relative flex min-h-screen text-left">
        <div className="flex flex-col justify-center flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute top-8 left-8">
            <img className="w-auto h-8" src={logo} alt="Cogoport Logo" />
          </div>
          <div className="w-full max-w-sm mx-auto">
            <div>
              <h2 className="text-3xl font-medium tracking-tight text-gray-900">
                Reset Password
              </h2>
              <p className="my-2 text-sm font-normal text-gray-500">
                Hi {user_m.fullName}, Looks like you are still using default password to login. Please reset your password
              </p>
            </div>

            <div className="mt-8">
              {error !== null && (
                <p className="text-sm text-red-700">{error}</p>
              )}
              <div className="mt-6">
                <Formik
                  initialValues={{ password: '', confirmPassword: '' }}
                  validationSchema={resetPasswordSchema}
                  onSubmit={null}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form
                      className="space-y-5"
                      onSubmit={handleSubmit}
                      noValidate={true}
                    >
                      <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            New Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              placeholder="New Password"
                              name="password"
                              type="password"
                              autoComplete
                              required={true}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                            {touched.password && (
                              <p className="text-xs text-red-700 ">
                                {errors.password}
                              </p>
                            )}
                          </div>
                        </div>
                      <div className="">
                        <div>
                          <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              type="password"
                              autoComplete
                              required={true}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                            {touched.confirmPassword && (
                              <p className="text-xs text-red-700 ">
                                {errors.confirmPassword}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <Button
                          type="submit"
                          className=" bg-primary"
                          loading={loading}
                        >
                          Reset
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-1/2 text-center md:flex-1 lg:block bg-grey-bg">
          <div className="absolute top-0 bottom-0 right-0 flex items-center">
            <img className="w-full h-full py-10" src={loginBg} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
