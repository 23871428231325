import React from 'react'

import Editor from '@monaco-editor/react'

const CodeEditorWindow = ({
  onChange,
  language,
  code,
  theme,
  className,
  height,
}) => {
  const handleEditorChange = (value) => {
    onChange(value)
  }

  return (
    <div className={`w-full overflow-hidden rounded-md overlay shadow-4xl`}>
      <Editor
        height={height ?? '90vh'}
        width={`100%`}
        language={language || 'html'}
        value={code}
        theme={theme}
        defaultValue={code}
        onChange={(value) => handleEditorChange(value)}
      />
    </div>
  )
}
export default CodeEditorWindow
