import { Routes, Route } from 'react-router-dom'
import './App.css'
import ProtectedRoutes from './components/protected routes/ProtectedRoutes'
import Auth from './pages/auth/auth'
import Landing from './pages/landing'
import Success from './pages/success'
import Playground from './pages/playground'
import Logout from './components/auth/logout'
import ResetPassword from './pages/auth/reset-password'
import SignUp from './pages/auth/signup'

function App() {
  return (
    <Routes>
      <Route path="/" element={<SignUp />} />
      <Route path="/login" element={<Auth />} />
      <Route path="logout" element={<Logout />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/success" element={<Success />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/playground/:courseId" element={<Playground />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
      <Route path="*" element={<Logout />} />
    </Routes>
  )
}

export default App
