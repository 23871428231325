import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../components/navigation/sidebar'
import app_api from '../config'

function Playground() {
  const params = useParams()
  const navigate = useNavigate()

  const [currentExercise, setCurrentExercise] = useState()
  const [courseContent, setCourseContent] = useState([])
  const [courseOrder, setCourseOrder] = useState([])

  const [user, setUser] = useState({})

  const [startTime, setStartTime] = useState(new Date().getTime())

  useEffect(() => {
    setStartTime(new Date().getTime())
  }, [currentExercise?.id])

  const getAllCourses = (userId) => {
    app_api
      .get(`/course/${params.courseId}/all/user/${userId}`)
      .then((res) => {
        let { courseContent, ...course } = res.data.data
        courseContent = courseContent.sort(
          (a, b) =>
            course.order.indexOf(a.courseContentId) -
            course.order.indexOf(b.courseContentId)
        )
        setCourseOrder(course.order)
        setCourseContent(courseContent)
        setCurrentExercise(courseContent?.[0])
      })
      .catch((err) => {})
  }

  const markContentComplete = (lid, status, values, toNext) => {
    let thisContent = courseContent
    thisContent = thisContent.map((c) => {
      if (c.courseContent.id === lid) {
        return {
          ...c,
          status,
          code: values?.code,
          githubLink: values?.githubLink,
        }
      } else return c
    })
    setCourseContent(thisContent)
    if (toNext) {
      goToNext()
    } else {
      setCurrentExercise(thisContent.find((c) => c.courseContent.id === lid))
    }
  }

  const markComplete = async (lid, status, values = {}, toNext = false) => {
    if (values !== null) {
      let now = new Date().getTime()
      app_api
        .put(
          `course-content/submit/user/${user?.data?.id}/courseContent/${lid}`,
          { ...values, timeSpent: now - startTime }
        )
        .then((res) => {
          setStartTime(now);
          markContentComplete(lid, status, values, toNext)
        })
        .catch((err) => {})
    } else {
      markContentComplete(lid, status, values, toNext)
    }
  }

  const goToNext = () => {
    const findIndex = courseOrder.findIndex(
      (id) => id === currentExercise.courseContent.id
    )
    let allCompleted =
      courseContent.filter((c) => c.status === 'PENDING').length === 0
    if (allCompleted) navigate('/success')
    else {
      setCurrentExercise(courseContent[findIndex + 1])
    }
    if (findIndex < courseOrder.length - 1) {
      setCurrentExercise(courseContent[findIndex + 1])
    } else {
      setCurrentExercise(courseContent?.[0])
    }
  }

  useEffect(() => {
    try {
      let user = JSON.parse(localStorage.getItem('cogoportKey'))
      setUser(user)
      getAllCourses(user?.data?.id)
    } catch (err) {}
  }, [])

  const updateCurrentExercise = (c) => {
    let content = courseContent.find((cc) => cc.courseContentId === c.id)
    setCurrentExercise(content)
  }

  return (
    <Sidebar
      categories={courseContent}
      currentContent={currentExercise}
      setCurrentExercise={updateCurrentExercise}
      goToNext={goToNext}
      markComplete={markComplete}
      markContentComplete={markContentComplete}
      user={user}
    ></Sidebar>
  )
}

export default Playground
